import {MouseEvent, useEffect, useMemo, useRef, useState} from "react";
import {hexToRgb, rgbDistSqr} from "./utils/colorUtils";
import {Box, CircularProgress} from "@mui/material";
import {nearestNeighbours} from "./nearestNeighbours";
import {StaticImageData} from "./types";

interface ColorableImageProps {
	selectedColor: string | null;
	imageData: StaticImageData;
	imageColors: string[];
	threshold?: number;
	onColorUsed: (color: string, zone: string) => void;
	forbiddenColors: string[];
	disabled?: boolean;
}

type PixelMap = {
	[zone: string]: Array<{ x: number, y: number }>
};

type InversePixelMap = {
	[index: number]: string
}

export default function ColorableImage(
	{
		disabled = false,
		selectedColor,
		imageData,
		imageColors,
		onColorUsed,
		forbiddenColors,
	}: ColorableImageProps,
) {
	const imageClusters = useMemo(() => {
		return [...imageColors, ...forbiddenColors]
	}, [ imageColors, forbiddenColors ])

	const canvasRef = useRef<HTMLCanvasElement>(null);

	const [map, setMap] = useState<PixelMap>({});
	const [inverseMap, setInverseMap] = useState<InversePixelMap>({});
	const [progress, setProgress] = useState(0);
	const [loaded, setLoaded] = useState(false);
	const [hoversForbiddenZone, setHoversForbiddenZone] = useState(false);

	useEffect(() => {
		const canvas = canvasRef.current;
		if (canvas === null) {
			return;
		}

		const ctx = canvas.getContext("2d");
		if (ctx === null) {
			return;
		}

		const image = new Image();
		image.crossOrigin = "Anonymous";
		image.src = imageData;
		setLoaded(false);
		image.onload = () => {
			// Calculate new dimensions with 33% of view height
			const newHeight = window.outerHeight * 0.45;
			const scaleFactor = newHeight / image.height;
			const newWidth = image.width * scaleFactor;


			canvas.width = newWidth;
			canvas.height = newHeight;

			// Draw the image onto the canvas
			ctx.drawImage(image, 0, 0, newWidth, newHeight);

			const posToString = (pos: { x: number, y: number }) => `${pos.x},${pos.y}`;

			let pixels = ctx.getImageData(0, 0, canvas.width, canvas.height).data;

			let pixelMap = new Map<string, { r: number, g: number, b: number }>();

			for (let x = 0; x < canvas.width; x++) {
				for (let y = 0; y < canvas.height; y++) {

					const index = (y * canvas.width + x) * 4;
					const red = pixels[index];
					const green = pixels[index + 1];
					const blue = pixels[index + 2];

					pixelMap.set(posToString({x: x, y: y}), {r: red, g: green, b: blue});
				}
			}

			const initialSize = canvas.width * canvas.height;
			const colorGroupResults = nearestNeighbours(
				imageClusters, canvas.width, canvas.height,
				rgbDistSqr,
				posToString, pixelMap,
				(progress: number) => {
					const ratio = 1 - progress / initialSize;
					setProgress(ratio);
				},
			);

			setLoaded(true);
			console.log(`${colorGroupResults.size} zones found in the image !`);
			console.log(Array.from(colorGroupResults.entries()));

			let map: PixelMap = {};
			let inverseMap: InversePixelMap = {};


			Array.from(colorGroupResults.entries())
				.forEach(entry => {
					map[entry[0]] = entry[1];
					entry[1].forEach(({x, y}) => {
						const index = y * canvas.width + x;
						inverseMap[index] = entry[0];

						const {r, g, b} = hexToRgb("#FFFF99");

						const predefinedColor = forbiddenColors.find(_ => _ === entry[0]);
						if (predefinedColor !== undefined) {
							const {r: pR, g: pG, b: pB} = hexToRgb(predefinedColor);
							pixels[4 * index] = pR;
							pixels[4 * index + 1] = pG;
							pixels[4 * index + 2] = pB;
						} else {
							pixels[4 * index] = r;
							pixels[4 * index + 1] = g;
							pixels[4 * index + 2] = b;
						}
						// pixels[4*index+3] = 255

					});
				});

			setMap(map);
			setInverseMap(inverseMap);

			ctx.putImageData(new ImageData(
				pixels,
				canvas.width, canvas.height,
			), 0, 0);
		};
	}, [forbiddenColors, imageClusters, imageData]);


	const onCanvasMove = (e: MouseEvent<HTMLCanvasElement>) => {
		const canvas = canvasRef.current;
		if (canvas === null) {
			return;
		}

		const ctx = canvas.getContext("2d");
		if (ctx === null) {
			return;
		}

		if (disabled) {
			setHoversForbiddenZone(true);
			return;
		}

		const rect = canvas.getBoundingClientRect();
		const x0 = Math.round(e.clientX - rect.left);
		const y0 = Math.round(e.clientY - rect.top);

		const colorGroup = inverseMap[y0 * canvas.width + x0];
		setHoversForbiddenZone(forbiddenColors.includes(colorGroup));
	};

	const onCanvasClick = (e: MouseEvent<HTMLCanvasElement>) => {
		if (disabled) {
			return;
		}
		if (selectedColor === null) {
			alert("Please select a color on the color wheel");
			return;
		}

		const canvas = canvasRef.current;
		if (canvas === null) {
			return;
		}

		const ctx = canvas.getContext("2d");
		if (ctx === null) {
			return;
		}

		const rect = canvas.getBoundingClientRect();
		const x0 = Math.round(e.clientX - rect.left);
		const y0 = Math.round(e.clientY - rect.top);

		// Get the pixel data of the top-left corner (position: 0,0)
		const data = ctx.getImageData(0, 0, canvas.width, canvas.height).data;

		const {r: sR, g: sG, b: sB} = hexToRgb(selectedColor);

		const colorGroup = inverseMap[y0 * canvas.width + x0];
		if (forbiddenColors.includes(colorGroup)) {
			return;
		}

		const zonePixels = map[colorGroup];

		for (const {x, y} of zonePixels) {
			const index = (y * canvas.width + x) * 4;

			data[index] = sR;
			data[index + 1] = sG;
			data[index + 2] = sB;
		}

		ctx.putImageData(new ImageData(
			data,
			canvas.width, canvas.height,
		), 0, 0);

		onColorUsed(selectedColor, colorGroup);
	};

	return <Box sx={{
		margin: 0,
		padding: 0,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	}}>
		<canvas
			className={hoversForbiddenZone ? "forbidden-zone" : ""}
			style={{
				margin: 0, padding: 0,
				borderRadius: "5px",
				// border: "solid 1px #eac7e7"
			}}
			ref={canvasRef}
			onMouseMove={onCanvasMove}
			onClick={onCanvasClick}
		/>
		<Box>{
			loaded ? <></> : <CircularProgress variant={"determinate"} value={progress * 100}/>
		}</Box>
	</Box>;
}