import React, {useCallback, useEffect, useMemo, useState} from "react";

import {AppBar, Box, Button, Card, CardActions, Tooltip, Typography, useTheme} from "@mui/material";
import Problem from "./problems/Problem";
import "./index.css";
// @ts-ignore
import ColorableDonald from "./images/donald_duck.png";
// @ts-ignore
import CharaDesignLucadashDa from "./images/lucadash_da_char_design.png";
// @ts-ignore
import InsideOut from "./images/inside_out.png";
// @ts-ignore
import Cartoon1 from "./images/cartoon_1.png"
// @ts-ignore
import Introduction from "./introduction/Introduction";
import Conclusion from "./conclusion/Conclusion";

const centeredStyle = {
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
};

function useProblems(onCompleted: () => void) {
	const problems = useMemo(() => {
		return [
			<Problem
				maxMoveCount={6}
				onComplete={onCompleted}
				key={2}
				image={ColorableDonald}
				forbiddenColors={["#000000", "#ffffff"]}
				colorClusters={["#2e7db7", "#f70002", "#fec140"]}
				schemeKey={"Split-Complementary"}
			/>,
			<Problem
				maxMoveCount={4}
				key={4}
				image={CharaDesignLucadashDa}
				forbiddenColors={["#000000", "#FDE2D7"]}
				colorClusters={["#FF0000", "#6F9DBE"]}
				schemeKey={"Complementary"}
				onComplete={onCompleted}
			/>,
			<Problem
				key={6}
				maxMoveCount={8}
				image={Cartoon1}
				forbiddenColors={[
					"#8B7E7B", "#FBF2E9", "#000000", "#FFFFFF"
				]}
				colorClusters={[
					"#BC8576", "#9C9E69", "#D7A57A"
				]}
				schemeKey={"Analogous"}
				onComplete={onCompleted}
			/>,
			<Problem
				key={5}
				maxMoveCount={15}
				image={InsideOut}
				forbiddenColors={[
					"#AAB7BF", "#000000", "#FFFFFF", "#FAF479"
				]}
				colorClusters={[
					"#E70106", "#98E57D", "#0066CB", "#D978CA"
				]}
				schemeKey={"Tetradic"}
				onComplete={onCompleted}
			/>,
		];
	}, [onCompleted]);

	return {
		array: problems,
		length: problems.length,
	};
}

type Stage = "intro" | "problem" | "conclusion";

function ProblemStage(
	{onComplete}: { onComplete: () => void },
) {
	const [problemIndex, setProblemIndex] = useState(0);
	const [disableNext, setDisableNext] = useState(true);

	const handleProblemCompletion = useCallback(() => {
		setDisableNext(false);
	}, []);

	useEffect(() => {
		setDisableNext(true);
	}, [problemIndex]);

	const problems = useProblems(handleProblemCompletion);

	const isLastProblem = problemIndex >= problems.length - 1;

	return <>
		<Box sx={centeredStyle}>
			<Box sx={{
				display: "flex",
				flexDirection: "row",
			}}>
				{problems.array[problemIndex]}
			</Box>
		</Box>
		<CardActions sx={{...centeredStyle, flexDirection: "column", justifyContent: "center", gap: 1}}>
			<Tooltip title={"Next problem"} enterDelay={500}>
				<span>
					<Button variant={"contained"} disabled={disableNext} onClick={
						() => {
							if (disableNext) {
								return;
							}
							if (isLastProblem) {
								onComplete();
							} else {
								return setProblemIndex(prev => prev + 1);
							}
						}
					}> {isLastProblem ? "Finish" : "Next Problem"} </Button>
				</span>
			</Tooltip>
			<Typography variant={"subtitle1"} color={"info"}>
				{`Problem ${problemIndex + 1} / ${problems.length}`}
			</Typography>
		</CardActions>
	</>;
}

function App() {
	const theme = useTheme();

	const [stage, setStage] = useState<Stage>("intro");

	const content = (() => {
		switch (stage) {
			case "intro":
				return <Introduction onComplete={() => setStage("problem")}/>;
			case "problem":
				return <ProblemStage onComplete={() => setStage("conclusion")}/>;
			case "conclusion":
				return <Conclusion/>;
		}
	})();

	useEffect(() => {
		const onKeyDown = (keyEvent: KeyboardEvent) => {
			console.log(`${keyEvent.key} pressed.`)
			if (keyEvent.key === "f") {
				document.body.requestFullscreen().then()
			}
		}

		window.addEventListener("keydown", onKeyDown)
		return () => {
			window.removeEventListener("keydown", onKeyDown)
		}
	}, []);

	return (
		<main style={{
			backgroundColor: theme.palette.background.default,
		}}>
			<AppBar enableColorOnDark={true} sx={{padding: 1, position: "relative"}}>
				<Typography variant={"h3"}>
					Learning Color Theory
				</Typography>
			</AppBar>
			<Box sx={{...centeredStyle, flexGrow: 1}}>
				<Card variant={"elevation"} sx={{
					display: "flex",
					flexDirection: "column",
					padding: 0,
				}}>
					{content}
				</Card>
			</Box>
		</main>
	);
}

export default App;
