import {Box, Button, Card, Typography} from "@mui/material";

interface IntroductionProps {
	onComplete: () => void;
}

const centeredStyle = {
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
};


export default function Introduction(
	{
		onComplete
	}: IntroductionProps,
) {

	return <Box sx={{...centeredStyle, padding: 2, gap: 1,}}>
		<Typography variant={"h6"}>
			Time to solve some problems !
		</Typography>
		<Typography marginY={2} component={"div"}>
			<ol>
				<li style={{ marginLeft: 35 }}>Read the instructions on how to color the problem's image: the number of
				color you should use and what feeling it should evoke</li>
				<li style={{ marginLeft: 35 }}>Click on the color wheel on the right to select a color</li>
				<li style={{ marginLeft: 35 }}>Click on a colorable zone (the cursor will display as a crosshair) to color this
				part of the image</li>
				<li style={{ marginLeft: 35 }}>Once you use the number of color specified, you will see the score update,
				base on how well you are doing according to color theory</li>
			</ol>
		</Typography>
		<Button variant={"contained"} onClick={() => {
			document.body.requestFullscreen()
			onComplete()
		}}>
			Enter full screen mode and start
		</Button>
	</Box>;
}