import {Box, CardContent, CardMedia, Divider, Paper, Typography, useTheme} from "@mui/material";
import ColorableImage from "./ColorableImage";
import ColorPicker from "./ColorPicker";
import Scorer, {SCHEME, SchemeKey} from "./Scorer";
import {useCallback, useEffect, useState} from "react";
import {StaticImageData} from "./types";

const centeredStyle = {
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
};

interface ProblemProps {
	image: StaticImageData;
	colorClusters: string[];
	schemeKey: SchemeKey;
	forbiddenColors?: string[];
	maxMoveCount?: number;
	autoSkip?: boolean;
	onComplete: () => void
}

export default function Problem(
	{
		onComplete,
		maxMoveCount = 6,
		image, colorClusters, schemeKey,
		forbiddenColors = ["#000000"]
	}: ProblemProps,
) {

	const [usedColors, setUsedColors] = useState<{ [zone: string]: string }>({});
	const [selectedColor, setSelectedColor] = useState<string | null>(null);
	const [colorLightness, setColorLightness] = useState(.6);
	const [_, setUglyUpdate] = useState(false);
	const [moveCount, setMoveCount] = useState(0);
	const [complete, setComplete] = useState(false);


	const colorResolution = 16;
	const remainingMoveCount = maxMoveCount - moveCount;

	const onColorUsed = (usedColor: string, zone: string) => {
		setMoveCount(prev => prev+1)
		setUsedColors(prev => {
			prev[zone] = usedColor;
			return prev;
		});
		setUglyUpdate(prev => !prev);
	};

	const theme = useTheme();

	const handleComplete = useCallback(() => {
		setComplete(true)
		onComplete()
	}, [ onComplete ])

	useEffect(() => {
		if (remainingMoveCount !== undefined && remainingMoveCount <= 0) {
			handleComplete()
		}
	}, [onComplete, remainingMoveCount]);

	return <Box sx={{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "stretch",
		width: "100%",
	}}>
		<Paper sx={{
			width: "100%",
			margin: 0, padding: 2,
			marginBottom: 2,
			borderBottomRightRadius: 0, borderBottomLeftRadius: 0,
			backgroundColor: theme.palette.primary.main
		}}>
			<Typography variant={"body1"} color={theme.palette.primary.contrastText}>
				{`${SCHEME[schemeKey].description}, using `}
				<span style={{ fontWeight: "bold", color: theme.palette.secondary.contrastText }}>
					{`${SCHEME[schemeKey].angles.length+1} colors.`}
				</span>
				<br/>
				{"You can only color yellow pre-colored zones. You have "}
				<span style={{ fontWeight: "bold", color: theme.palette.secondary.contrastText }}>
					{ `${remainingMoveCount} ${remainingMoveCount > 1 ? "moves" : "move"}` }
				</span>
				{` remaining !`}
			</Typography>
		</Paper>
		<Box sx={{
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-evenly",
			alignItems: "stretch",
		}}>
			<CardMedia sx={centeredStyle}>
				<ColorableImage
					disabled={complete}
					forbiddenColors={forbiddenColors}
					selectedColor={selectedColor}
					imageData={image}
					imageColors={colorClusters}
					onColorUsed={onColorUsed}
				/>
			</CardMedia>

			<CardContent sx={centeredStyle}>
				{/*<Slider*/}
				{/*  value={colorLightness}*/}
				{/*  min={0} max={1} step={0.01}*/}
				{/*  onChange={(e, v) => setColorLightness(v as number)}/>*/}
				<ColorPicker
					colorLightness={colorLightness}
					selectedColor={selectedColor}
					dimension={120}
					blacklistedHueRanges={(complete ? [{min: 0, max: 360}] : [])}
					sx={{width: "100%", height: "100%", zIndex: 1}}
					onColorCLicked={setSelectedColor}
					colorResolution={colorResolution}
				/>
				<Scorer
					onComplete={handleComplete}
					colorsHex={Object.values(usedColors)}
					schemeKey={schemeKey}
					toleranceDeg={1.5 * (360 / colorResolution)}
				/>
			</CardContent>
		</Box>
	</Box>;
}