import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {createTheme, ThemeProvider} from "@mui/material";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#331230"
    },
    secondary: {
      main: "#251233"
    },
    info: {
      main: "#331220"
    },
    text: {
      primary: "#ffe0ee",
      secondary: "#87b899",
    }
  }
})

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);