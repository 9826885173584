import {useEffect, useState} from "react";
import {Box, Button, Typography, useTheme} from "@mui/material";

interface ConclusionProps {

}

const centeredStyle = {
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
};


export default function Conclusion(
	{}: ConclusionProps,
) {
	const [referrer, setReferrer] = useState('');

  useEffect(() => {
    // Get the referring URL when the component mounts
    const referringURL = document.referrer;
    setReferrer(referringURL);
  }, []);

	return <Box sx={{...centeredStyle, padding: 2,}}>
		<Typography variant={"h6"} margin={1}>
			{ "Great job on this problem-solving exercise !"}
		</Typography>
		<Button variant={"contained"} onClick={() => {
			document.exitFullscreen();
		}}>
			Exit full screen and continue the course...
		</Button>
	</Box>;
}